<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框
     */
    // 引入组件
    import DrawerExample4 from '../../../components/drawer/example4/example4.vue';

    export default {
        // 注册组件
        components: {
            DrawerExample4
        },
        data() {
            return {
                // 定义控制对话框显示的变量
                modal_visible: false
            }
        },
        mounted() {

        },
        methods: {
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.modal_visible = true
            }
        }
    }
</script>
<template>
    <div>
        <!--打开对话框的按钮-->
        <a-button type="primary" @click="showModal">
            打开对话框
        </a-button>
        <!--:ok="modalClose"          给 组件 定义确定函数-->
        <!--:cancel="modalClose"      给 组件 定义取消函数-->
        <!--:visible="modal_visible"  给 组件 定义控制显示的变量-->
        <DrawerExample4 :ok="modalClose" :cancel="modalClose" :visible="modal_visible"></DrawerExample4>
    </div>
</template>
<style scoped>

</style>
